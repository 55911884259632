import React from "react";
import { CookieNotice } from "gatsby-cookie-notice";
import * as styles from "./CookieNotice.module.scss";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";
import { privacy } from "../../data/ConstantData";

function CookieInfo() {
  return (
    <CookieNotice
      backgroundClasses={styles.background}
      backgroundWrapperClasses={styles.backgroundWrapper}
      acceptButtonText="Akceptuję wszystko"
      declineButton={true}
      declineButtonText="Odrzuć"
      declineButtonClasses={styles.buttonsClass}
      acceptButtonClasses={`${styles.buttonsClass} ${styles.acceptBtn}`}
      personalizeButtonText={"Dostosuj"}
      wrapperClasses={styles.buttonWrapper}
      personalizeButtonClasses={styles.buttonsClass}
      personalizeValidationText="Zapisz"
      cookies={[
        {
          name: "necessary",
          editable: false,
          default: true,
          title: "Podstawowe",
          text: "Podstawowe ciasteczka są niezbędne do prawidłowego funkcjonowania strony. Stronie nie może funkcjinować właściwie bez nich.",
        },
        {
          name: "gatsby-gdpr-google-analytics",
          editable: true,
          default: true,
          title: "Google Analytics",
          text: "Google Analytics jest to narzędzie do analizy statysk, które pomaga mierzyć oglądalność w wyszukiwarce Google.",
        },
        {
          name: "gatsby-gdpr-facebook-pixel",
          editable: true,
          default: true,
          title: "Facebook Pixel",
          text: "Facebook Pixel jest to narzędzie do analizy statysk, które pomaga mierzyć oglądalność na stronie Facebook.",
        },
      ]}
    >
      <Container>
        <ImageContainer>
          <StaticImage alt="cookies" src="../../assets/images/cookies.png" />
        </ImageContainer>

        <TextWrapper>
          <H3>Niniejsza strona korzysta z plików cookie</H3>
          <PMicro>
            Chcielibyśmy Cię poinformować, że podczas odwiedzania naszego
            serwisu, za pomocą plików cookies lub innych podobnych technologii
            (zwanych łącznie cookies), my lub nasi partnerzy, gromadzimy
            informacje dotyczące Twojej aktywności na Sprzedamszkode.pl lub
            innych serwisach. Dzięki temu możemy zapewnić Ci wygodne i
            bezpieczne korzystanie z naszej platformy oraz naszych usług,
            dostosować wyświetlane treści i oferty, a także umożliwić
            korzystanie z funkcji mediów społecznościowych.
          </PMicro>
          <PSmall>
            Sprawdź dokładnie{" "}
            <A href={privacy.privacy_url}>politykę plików cookies</A>
          </PSmall>
        </TextWrapper>
      </Container>
    </CookieNotice>
  );
}

export default CookieInfo;

const ImageContainer = styled.div`
min-width: 100px;;
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1200px;
`;
const A = styled.a`
  color: white;
  :hover {
    color: white;
  }
`;

const PSmall = styled.p`
  font-size: 0.9rem;
`;

const PMicro = styled.p`
  font-size: 0.8rem;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const H3 = styled.h3`
  @media screen and (max-width: 568px) {
  }
`;
