import React, { useState } from "react";
import styled from "styled-components";
import Hyphenated from "react-hyphen";
import PropTypes from "prop-types";
import pl from "hyphenated-pl";
import Button from "../Button/Button";
import AOSanimation from "../AOSanimation";
import ModalForm from "../ModalForm";

function TransparentContainer({ title, desc, image, children, id, button }) {
  const [modalShow, setModalShow] = useState(false);
  return (
    <RelativeContainer>
      <AOSanimation aos="fade-up" aosDuration="300">
        {image}
      </AOSanimation>
      <AOSanimation aos="fade-in">
        <Container id={id}>
          <Hyphenated language={pl}>
            <Title>{title}</Title>
            {children}
            <Description>{desc}</Description>
            {button && (
              <>
                <ModalForm
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                />
                <ButtonBox>
                  <Button onClick={() => setModalShow(true)}>
                    Wypełnij formularz
                  </Button>
                </ButtonBox>
              </>
            )}
          </Hyphenated>
        </Container>
      </AOSanimation>
    </RelativeContainer>
  );
}

TransparentContainer.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  image: PropTypes.node,
  children: PropTypes.node,
  id: PropTypes.string,
  button: PropTypes.bool,
};

export default TransparentContainer;

const RelativeContainer = styled.div`
  position: relative;
`;

const Container = styled.div`
  position: relative;
  margin: auto;
  padding: 30px;
  flex-direction: column;
  width: 100%;
  height: auto;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 2rem;
  margin-top: 150px;
  z-index: 0;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  @media screen and (max-width: 1200px) {
    padding: 35px 15px;
  }
`;
const Title = styled.h3`
  font-size: clamp(1.2rem, 3.4rem, 4rem);
  font-weight: 500;
  text-align: center;
  margin-bottom: 35px;

  text-shadow: 0.1em 0.1em 0 rgba(0, 0, 0, 0.1);

  @media screen and (max-width: 800px) {
    margin-bottom: 25px;
    font-size: clamp(1.2rem, 2.5rem, 4rem);
  }
`;

const Description = styled.p`
  font-size: clamp(1.1rem, 1.5rem, 1.9rem);
  width: 95%;
  line-height: 1.3;
  margin: auto;
  text-align: left;
  @media screen and (min-width: 1200px) {
    width: 85%;
  }

  @media screen and (max-width: 800px) {
    text-align: justify;
    width: 85%;
  }
`;

const ButtonBox = styled.div`
  width: 40%;
  margin: auto;
  margin-top: 50px;
  @media screen and (max-width: 1000px) {
    width: 100%;
  }
`;
