import React from "react";
import TransparentContainer from "../TransparentContainer/TransparentContainer";
import { myObjects } from "../../data/TextData";
import styled from "styled-components";

function HowMuch() {
  return (
    <Wrapper id="howMuchGet" navbarHeight="10px">
      <TransparentContainer
        title={myObjects.howMuch.title}
        image={myObjects.howMuch.image}
        desc={myObjects.howMuch.desc}
        button
      />
    </Wrapper>
  );
}

export default HowMuch;

const Wrapper = styled.div`
  @media screen and (min-width: 1240px) {
    padding-top: ${(props) => props.navbarHeight};
  }
`;
