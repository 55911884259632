import React from "react";
import { StaticImage } from "gatsby-plugin-image";

export const InsuranceLogoArray = [
  <StaticImage
    src="../../assets/images/InsuranceLogos/generali.svg"
    alt="generali"
    key={1}
  />,
  <StaticImage
    src="../../assets/images/InsuranceLogos/uniqua.svg"
    alt="uniqua"
    key={2}
  />,
  <StaticImage
    src="../../assets/images/InsuranceLogos/pzu.png"
    alt="pzu"
    key={3}
  />,
  <StaticImage
    src="../../assets/images/InsuranceLogos/link4.svg"
    alt="link4"
    key={4}
  />,
  <StaticImage
    src="../../assets/images/InsuranceLogos/alianz.svg"
    alt="alianz"
    key={5}
  />,
  <StaticImage
    src="../../assets/images/InsuranceLogos/axa.png"
    alt="axa"
    key={6}
  />,
  <StaticImage
    src="../../assets/images/InsuranceLogos/proama.png"
    alt="proama"
    key={7}
  />,
  <StaticImage
    src="../../assets/images/InsuranceLogos/hdi.png"
    alt="hdi"
    key={8}
  />,
  <StaticImage
    src="../../assets/images/InsuranceLogos/warta.png"
    alt="warta"
    key={9}
  />,
  <StaticImage
    src="../../assets/images/InsuranceLogos/liberty-direct.png"
    alt="liberty-direct"
    key={10}
  />,
  <StaticImage
    src="../../assets/images/InsuranceLogos/aviva.png"
    alt="aviva"
    key={11}
  />,
  <StaticImage
    src="../../assets/images/InsuranceLogos/mtu.png"
    alt="mtu"
    key={12}
  />,
  <StaticImage
    src="../../assets/images/InsuranceLogos/nationale-nederlanden.png"
    alt="nationale"
    key={13}
  />,
  <StaticImage
    src="../../assets/images/InsuranceLogos/compensa.png"
    alt="compensa"
    key={14}
  />,
  <StaticImage
    src="../../assets/images/InsuranceLogos/ergo-hestia.svg"
    alt="ergo-hestia"
    key={15}
  />,
  <StaticImage
    src="../../assets/images/InsuranceLogos/benefia.png"
    alt="benefia"
    key={16}
  />,
  <StaticImage
    src="../../assets/images/InsuranceLogos/interrisk.png"
    alt="interrisk"
    key={17}
  />,
  <StaticImage
    src="../../assets/images/InsuranceLogos/rast.png"
    alt="rast"
    key={18}
  />,
];
