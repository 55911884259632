import React from "react";
import styled from "styled-components";
import TransparentContainer from "../TransparentContainer/TransparentContainer";
import { myObjects } from "../../data/TextData";
import { HowItWorksArr } from "../../data/HowItWorksData";
import AOSanimation from "../AOSanimation";

function HowItWorks() {
  return (
    <Wrapper navbarHeight="10px" id="howItWorks">
      <TransparentContainer
        image={myObjects.howItWorks.image}
        title={myObjects.howItWorks.title}
        desc={myObjects.howItWorks.desc}
      >
        <ImageContainer>
          {HowItWorksArr.map((el, index) => (
            <SingleImage key={index}>
              <AOSanimation aos="fade-up" aosDelay={50}>
                {el.image}
                <p>{el.title}</p>
              </AOSanimation>
            </SingleImage>
          ))}
        </ImageContainer>
      </TransparentContainer>
    </Wrapper>
  );
}

export default HowItWorks;

const Wrapper = styled.div`
  @media screen and (min-width: 1240px) {
    padding-top: ${(props) => props.navbarHeight};
  }
`;

const ImageContainer = styled.div`
  display: flex;

  flex-wrap: nowrap;
  margin-bottom: 25px;
  justify-content: center;
  position: relative;
  @media screen and (max-width: 1100px) {
    flex-wrap: wrap;
  }
`;

const SingleImage = styled.div`
  grid-area: one;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  padding: 8px;
  margin: 5px;
  display: flex;
  font-size: clamp(1.2rem, 1.5rem, 3rem);
  text-align: center;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 195px;
  font-weight: 500;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  @media screen and (max-width: 470px) {
    width: 100%;
  }

  @media screen and (max-width: 1490px) {
    font-size: 1.2rem;
    padding: 5px;
  }
`;
