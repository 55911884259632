import React, { useEffect, useState } from "react";
import NavbarSticky from "./NavbarSticky";
import styled from "styled-components";
import { WrapperStyle } from "./NavbarSticky";

function ShowNavbarHandler() {
  const [show, setShow] = useState(false);

  
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.addEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.pageYOffset > 350) {
      setShow(true);
    } else {
      setShow(false);
    }
  };

  return (
    <Wrapper isShowed={show}>
      <NavbarSticky />
    </Wrapper>
  );
}

export default ShowNavbarHandler;

const Wrapper = styled(WrapperStyle)`
  top: -80px;
  transition: ${(props) =>
    props.isShowed ? "0.3s ease-in-out, visibility 0.3s ease-in-out" : "none"};
  visibility: ${(props) => (props.isShowed ? "visible" : "hidden")};
  transform: ${(props) => props.isShowed && "translateY(80px)"};
  opacity: ${(props) => (props.isShowed ? "1" : "0")};
`;
