import React from "react";
import styled from "styled-components";
import PhoneIcon from "../assets/images/phoneIcon.svg";
import { contact } from "../data/ConstantData";
import PhoneNumber from "./PhoneNumber";
function PhoneWrapperIndex() {
  return (
    <Wrapper>
      <PhoneNumber />
    </Wrapper>
  );
}

export default PhoneWrapperIndex;

const Wrapper = styled.div`
  height: 90px;
  display: flex;
  justify-content: flex-end;
  z-index: 2;
  padding: 20px;

  @media screen and (max-width: 1240px) {
    padding: 80px 20px 20px 20px;
  }
  @media screen and (max-width: 750px) {
    justify-content: flex-start;
    padding: 20px;
  }
  @media screen and (max-width: 680px) {
    ::after {
      height: 300px;
    }
  }
`;
