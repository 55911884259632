import React from "react";
import HowItWorksImage from "../assets/images/HowItWorks/howItWorksImg.svg";
import Money from "../assets/images/money.svg";

import * as styles from "../components/HowItWorks/HowItWorks.module.scss";

// Hero - początkowa treść
export const HeroText = {
  doYouKnow: {
    title: `Czy wiesz, że...?`,
    desc: `W większości przypadków firmy ubezpieczeniowe zaniżają rzeczywistą kwotę
    odszkodowania za kolizję, bądź wypadek z OC sprawcy.Jest to aż 99% przypadków zaniżonych
    odszkodowań. Dzięki przesłaniu do nas zgłoszenia, możesz uzykać
    dopłatę do odszkodwania, które już otrzymałeś. Pieniądze przelejemy Ci w ciagu 24h!
    
   `,
  },
  dontWasteTime: {
    title: `Nie trać czasu...`,
    desc: ` Próbując uzyskać większą kwotę od ubezpieczyciela może okazać
    się, że zmarnujesz dużo czasu i pieniędzy, przy czym nie uzyskasz żadnego efektu w postaci dodatkowej gotówki.
     Dlaczego tak jest? Ponieważ firmy ubezpieczeniowe dobrze wiedzą, że nie posiadasz
    armii prawników oraz wystarczającej ilości czasu, aby zająć się odpowiednio i profesjonalnie Twoją sprawą.
    Dlatego w oczach ubezpieczyciela z góry będziesz na przegranej pozycji. Wystarczy, że złożysz wniosek o dopłatę do odszkodowania, a my zajmiemy się resztą.`,
  },
};

export const myObjects = {
  howItWorks: {
    image: <HowItWorksImage className={styles.ImageLeft} />,
    title: "Jak to działa...?",
    desc: `To bardzo proste! Wysyłasz do nas potrzebne dokumenty, a my oceniamy, ile
        możemy zapłacić za Twoją szkodę. Pieniądze otrzymasz w ciągu 24 godzin
        od podpisania umowy. Wycena dopłaty do szkody jest całkowicie bezpłatna,
        więc nic na tym nie tracisz. Średnia wysokość dopłaty to ponad 2 tys.
        zł. Możliwość ubiegania się o odszkodowanie wynosi do 3 lat, więc jeśli
        w tym okresie miałeś jakąś szkodę, skontaktuj się z nami. Jeśli nie
        jesteś pewny, czy należy Ci się odszkodowanie z tytułu kolizji możemy to
        sprawdzić.Przesłane do nas Twoje dane są całkowicie bezpieczne, dzięki protokołowi SSL
        użytemu na stronie.`,
  },
  stats: {
    title: "Statystyki..",
  },

  howMuch: {
    title: "Ile mogę otrzymać...?",
    image: <Money className={styles.ImageRight} />,
    desc: `Wszystko zależy od szkody na pojeździe i wyliczeń
    rzeczoznawcy z ubezpieczalni, który oszacował szkodę. Aby dokładnie dowiedzieć
    się ile dopłaty za szkodę z OC sprawcy możesz otrzymać, prześlij do nas formularz
    zgłoszeniowy. Jeśli nie wiesz jakie pliki są potrzebne do ocenienia Twojej szkody, wyślij nam swoje
    dane kontaktowe, a nasz doradca skontaktuje się z Tobą w ciągu kilku minut. Możesz zadzwonić również
    osobiście pod numer 780-158-159 i zapytać o szczegóły. Użyj poniższego przycisku i wypełnij formularz, aby skontaktował się z Tobą nasz doradca.
    `,
  },
};
