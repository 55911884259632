import React from "react";
import SprzedamSzkodeText from "./SprzedamSzkodeText/SprzedamSzkodeText";
import styled from "styled-components";

function SprzedamszkodeTextOnTop() {
  return (
    <TextWrapper>
      <SprzedamSzkodeText />
    </TextWrapper>
  );
}

export default SprzedamszkodeTextOnTop;

const TextWrapper = styled.div`
  position: absolute;
  left: 3%;
  top: 2%;

  @media screen and (max-width: 770px) {
    padding-top: 230px;
    left: 50%;
    margin-left: -250px;
  }
  @media screen and (max-width: 550px) {
    margin-left: -220px;
    padding-top: 240px;
  }
  @media screen and (max-width: 460px) {
    margin-left: -200px;
    padding-top: 240px;
  }

  @media screen and (max-width: 425px) {
    margin-left: -170px;
    padding-top: 200px;
  }

  @media screen and (max-width: 370px) {
    margin-left: -150px;
  }
  @media screen and (max-width: 330px) {
    padding-top: 210px;
    margin-left: -145px;
  }
`;
