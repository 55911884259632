import React from "react";
import { Helmet } from "react-helmet";
import useSiteMetadata from "../hooks/use-site-metadata";
import Hero from "../components/Hero";
import Stats from "../components/Stats/Stats";
import Footer from "../components/Footer";
import HowItWorks from "../components/HowItWorks/HowItWorks";
import HowMuch from "../components/HowMuch/HowMuch";
import ContentWrapper from "../components/ContentWrapper";
import GlobalStyle from "../styles/GlobalStyles";
import InsuranceList from "../components/InsuranceList/InsuranceList";
import "./index.scss";
import Navbar from "../components/Navbar";
import ShowNavbarHandler from "../components/ShowNavbarHandler";
import CookieInfo from "../components/CookieNotice/CookieNotice";

const IndexPage = () => {
  const { title, description } = useSiteMetadata();

  return (
    <>
      <Helmet>
        <html lang="pl" />
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <CookieInfo />
      <GlobalStyle />
      <Navbar />
      <ShowNavbarHandler />
      <Hero />
      <ContentWrapper>
        <HowItWorks />
      </ContentWrapper>
      <InsuranceList />
      <ContentWrapper>
        <Stats />
        <HowMuch />
      </ContentWrapper>
      <Footer marginTop />
    </>
  );
};
export default IndexPage;
