import React from 'react';
import styled from 'styled-components';
import { InsuranceLogoArray } from './InsuranceImageArray';
import AOSanimation from '../AOSanimation';
function InsuranceList() {
  return (
    <AOSanimation aos="fade-up">
      <Container>
        <LeftText>Odzyskujemy pieniądze między innymi od:</LeftText>
        <CenterBox>
          {InsuranceLogoArray.map((el, index) => (
            <SingleFrame key={index} >
              <SingleLogo>{el}</SingleLogo>
            </SingleFrame>
          ))}
        </CenterBox>
        <RightText>...a także pozostałych ubezpieczycieli</RightText>
      </Container>
    </AOSanimation>
  );
}

export default InsuranceList;

const Container = styled.div`
  width: 100%;
  background: white;
  border-top: 20px solid #ffdd00;
  border-bottom: 20px solid #ffdd00;
  margin: 70px 0;
`;

const CenterBox = styled.div`
  width: 85%;
  margin: 20px auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border-radius: 5px;
  padding: 20px 0;
`;

const LeftText = styled(CenterBox)`
  justify-content: flex-start;
  background: none;
  margin: 10px auto;
  font-size: 2rem;
  font-weight: 600;
  color: #575757;
  @media screen and (max-width: 600px) {
    text-align: center;
  }
`;

const RightText = styled(LeftText)`
  justify-content: flex-end;
  font-size: 1.8rem;
  font-weight: 700;
`;

const SingleFrame = styled.div`
  background: white;
  margin: 15px 10px;
  width: 190px;
  padding: 5px;
  display: flex;
  justify-content: center;
  justify-items: center;

  @media screen and (max-width: 500px) {
    width: 120px;
    padding: 0;
    margin: 10px 5px;
  }
`;
const SingleLogo = styled.div`
  margin: auto;
  display: flex;
  justify-content: center;
  width: 100%;
`;
