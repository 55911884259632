import React from "react";
import TransparentContainer from "../TransparentContainer/TransparentContainer";
import styled from "styled-components";
import { myObjects } from "../../data/TextData";
import PercentageCircle from "../PercentageCircle";

function Stats() {
  return (
    <WrapperSelector id="statics" navbarHeight="20px">
      <TransparentContainer title={myObjects.stats.title}>
        <Wrapper>
          <PercentageCircle percent={99} text="Zaniżonych odszkodowań OC/AC" />
          <PercentageCircle percent={50} text="2450 zł średnia dopłat" />
          <PercentageCircle percent={99} text="Zadowolonych klientów" />
        </Wrapper>
      </TransparentContainer>
    </WrapperSelector>
  );
}

export default Stats;

const Wrapper = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: space-around;
  @media screen and (max-width: 1050px) {
    flex-direction: column;
    align-items: center;
  }
`;

const WrapperSelector = styled.div`
  @media screen and (min-width: 1240px) {
    padding-top: ${(props) => props.navbarHeight};
  }
`;
