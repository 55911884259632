import React from "react";
import styled from "styled-components";
import Hyphenated from "react-hyphen";
import pl from "hyphenated-pl";
import Crash from "../assets/images/carcrash.svg";
import Background from "../assets/images/backgroundPurple1.svg";
import * as styles from "./Hero.module.scss";
import Form from "../components/Form/Form";
import { HeroText } from "../data/TextData";
import Clock from "../assets/images/Hero/clock.svg";
import Question from "../assets/images/Hero/question.svg";

function Hero() {
  return (
    <>
      <HeroContainer>
        <CarCrash />
        <Background className={styles.background} />
        <ColumnWrapper>
          <Hyphenated language={pl}>
            <Text>
              <Question className={styles.question} />
              <Header>{HeroText.doYouKnow.title}</Header>
              <Paragraph>{HeroText.doYouKnow.desc}</Paragraph>
            </Text>
            <HigherText>
              <Clock className={styles.clock} />
              <Header>{HeroText.dontWasteTime.title}</Header>
              <Paragraph>{HeroText.dontWasteTime.desc}</Paragraph>
            </HigherText>
          </Hyphenated>
          <FormWrapper id="form">
            <Form />
          </FormWrapper>
        </ColumnWrapper>
      </HeroContainer>
    </>
  );
}

export default Hero;

const CarCrash = styled(Crash)`
  width: 40%;
  top: -20px;
  position: absolute;
  opacity: 1;
  z-index: 2;
  min-width: 350px;

  @media screen and (min-width: 1200px) {
    top: 0;
    width: 43%;
  }

  @media screen and (min-width: 1500px) {
    top: -40px;
    width: 43%;
  }

  @media screen and (max-width: 1200px) {
    width: 50%;
    top: -18px;
  }
  @media screen and (max-width: 1000px) {
    width: 50%;
    top: 0%;
  }
  @media screen and (max-width: 900px) {
    top: -10px;
    width: 55%;
  }
  @media screen and (max-width: 800px) {
    top: -4%;
    width: 65%;
  }
  @media screen and (max-width: 670px) {
    top: -5%;
    width: 75%;
  }
  @media screen and (max-width: 600px) {
    top: -2%;
  }
  @media screen and (max-width: 500px) {
    top: 0%;
    width: 80%;
  }
  @media screen and (max-width: 370px) {
    top: -1%;
    width: 100%;
  }

  @media screen and (max-width: 290px) {
    top: 20px;
    width: 100%;
  }
`;

const HeroContainer = styled.div`
  justify-content: left;
  width: 100%;
  z-index: 1;
  background: linear-gradient(to left, #422784, #6a4fd4);
`;

const ColumnWrapper = styled.div`
  width: 100%;
  position: relative;
  bottom: 0;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  align-content: space-around;
  padding-top: 45px;
  z-index: 4;
  @media screen and (max-width: 800px) {
    flex-direction: column-reverse;
    height: auto;
    align-items: center;
    padding-top: 140px;
  }

  @media screen and (min-width: 1500px) {
    padding-top: 60px;
  }

  @media screen and (min-width: 1920px) {
    max-width: 1800px;
    padding-top: 80px;
    margin: auto;
  }
  @media screen and (max-width: 400px) {
    padding-top: 110px;
  }
`;

const Text = styled.div`
  position: relative;
  width: 30%;
  padding: 35px;
  margin-bottom: 20px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  z-index: 2;
  line-height: 1.3;
  text-align: left;
  font-size: clamp(1.2rem, 1.6rem, 2rem);
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  @media screen and (max-width: 1200px) {
    display: none;
  }
`;

const HigherText = styled(Text)`
  @media screen and (max-width: 1200px) {
    display: block;
    width: 45%;
  }
  @media screen and (max-width: 800px) {
    margin-top: 35px;
    width: 90%;
  }
`;

export const FormWrapper = styled(Text)`
  height: auto;
  width: 35%;
  z-index: 2;
  padding-top: 10px;
  @media screen and (max-width: 1200px) {
    display: block;
    width: 45%;
  }
  @media screen and (max-width: 800px) {
    width: 90%;
    padding: 15px;
  }
`;

export const Header = styled.h3`
  color: white;
  opacity: 1;
  font-size: clamp(1.4rem, 2.3rem, 3.4rem);
  margin-bottom: 20px;
  text-shadow: 0.1em 0.1em 0 rgba(0, 0, 0, 0.2);

  @media screen and (max-width: 800px) {
    text-align: center;
  }
  @media screen and (max-width: 500px) {
    font-size: 2rem;
  }
`;
const Paragraph = styled.p`
  font-size: clamp(1.1rem, 1.3rem, 1.9rem);
  color: white;
  text-align: justify;
`;
