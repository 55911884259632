import React from "react";

//**********************How It Works Section Images ********************////
import Kolizja from "../assets/images/HowItWorks/kolizja.svg";
import Kosztorys from "../assets/images/HowItWorks/kosztorys.svg";
import Kontakt from "../assets/images/HowItWorks/kontakt.svg";
import Umowa from "../assets/images/HowItWorks/umowa.svg";
import Przelew from "../assets/images/HowItWorks/przelew.svg";

export const HowItWorksArr = [
  {
    title: "1. Kolizja samochodowa",
    image: <Kolizja />,
  },
  {
    title: "2. Kosztorys ubezpieczyciela",
    image: <Kosztorys />,
  },
  {
    title: "3. Kontakt z nami",
    image: <Kontakt />,
  },
  {
    title: "4. Podpisanie umowy",
    image: <Umowa />,
  },
  {
    title: "5. Przelew w 24h",
    image: <Przelew />,
  },
];
