import React from "react";
import styled from "styled-components";
import SprzedamszkodeTextOnTop from "./SprzedamszkodeTextOnTop";
import PhoneWrapperIndex from "./PhoneWrapperIndex";
import Header from "./Header";

function Navbar() {
  return (
    <Wrapper>
      <PhoneWrapperIndex />
      <SprzedamszkodeTextOnTop />
      <Header />
    </Wrapper>
  );
}
export default Navbar;

const Wrapper = styled.div`
  width: 100%;
  ::after {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 250px;
    background: white;

  }
  @media screen and (min-width: 1000px) {
    ::after {
      content: "";
      position: absolute;
      top: 0;
      width: 100%;
      height: 500px;
      background: white;

    }
  }
`;
