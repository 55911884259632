import React, { useEffect } from "react";
import AOS from "aos";
import { Watch } from "scrollmonitor-react";
import "aos/dist/aos.css";
import styled from "styled-components";

export default Watch(function AOSanimation(props) {
  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    AOS.init({
      duration: 1300,
    });
    AOS.refresh();
  }, []);

  return (
    <Div
      data-aos={props.aos}
      data-aos-delay={props.aosDelay}
      data-aos-duration={props.aosDuration}
    >
      {props.children}
    </Div>
  );
});

const Div = styled.div`
  margin: 0;

  padding: 0;
`;
