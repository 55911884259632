import React, { useState, useEffect } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useInView } from "react-intersection-observer";
import AnimatedProgressProvider from "./AnimationProgressProvider";
import { easeQuadInOut } from "d3-ease";
import styled from "styled-components";
import PropTypes from "prop-types";

const PercentageCircle = ({ percent, text }) => {
  const [isVisible, setIsVisible] = useState(false);
  const { ref, inView } = useInView({ threshold: 0.5 });

  useEffect(() => {
    if (inView) {
      setIsVisible(true);
    }
  }, [inView]);

  return (
    <CircleContainer>
      <div style={{ width: 200, height: 200 }} ref={ref}>
        <AnimatedProgressProvider
          valueStart={0}
          valueEnd={isVisible ? percent : "0"}
          duration={1.4}
          easingFunction={easeQuadInOut}
        >
          {(value) => {
            const roundedValue = Math.round(value);
            return (
              <CircularProgressbar
                value={value}
                text={`${roundedValue}%`}
                strokeWidth={10}
                /* This is important to include, because if you're fully managing the
    animation yourself, you'll want to disable the CSS animation. */
                styles={buildStyles({
                  strokeLinecap: "butt",
                  pathTransition: "none",
                  pathColor: `rgba(255, 221, 0, ${isVisible ? 1 : 0.5})`,
                  textColor: "#ccc",
                  trailColor: "#d6d6d6",
                  backgroundColor: "#3e98c7",
                })}
              />
            );
          }}
        </AnimatedProgressProvider>
      </div>
      <Text>{text}</Text>
    </CircleContainer>
  );
};

PercentageCircle.propTypes = {
  percent: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
};

export default PercentageCircle;

const Text = styled.h3`
  text-align: center;
  margin-top: 15px; ;
`;

const CircleContainer = styled.div`
  display: flex;
  width: 200px;
  flex-direction: column;
  margin-bottom: 20px;
`;
